import { useEffect, useState } from "react";
import { getLastEventDate } from "../api/DetectionsRequests";
import Card from "../card/Card";
import { secToTextWithoutUselessUnit } from "../helpers/DateHelper";

interface IProps {}

export const LastEventDateCard = (props: IProps) => {
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const [lastEventDate, setLastEventDate] = useState<string | undefined>(undefined);
  const [lastRequestTimestamp, setLastRequestTimestamp] = useState<number | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  const loadLastEventDate = async (isMounted: boolean) => {
    const response = await getLastEventDate();
    if (!isMounted) return;  // Prevent state updates if the component is unmounted

    setLoading(false);

    if (response.error) {
      console.error("error during get last event date loading", response?.error);
      return;
    }

    setLastRequestTimestamp(Date.now());
    setLastEventDate(response.data?.date);
  };

  useEffect(() => {
    let isMounted = true;  // Track if the component is still mounted

    // Start the timer
    const newTimer = setInterval(async () => {
      await loadLastEventDate(isMounted);
    }, 15000);
    setTimer(newTimer);

    // Cleanup when the component is unmounted
    return () => {
      isMounted = false;  // Set the flag to false when the component is unmounted
      if (newTimer) {
        clearInterval(newTimer);  // Clear the interval if it exists
      }
    };
  }, []);

  return (
    <Card
      forceLoadingDisplay={isLoading}
      style={{
        width: "350px",
        marginRight: "10px",
        marginBottom: "15px",
        display: "inline-block",
      }}
      title={"Dernières données reçues"}
      value={
        lastEventDate === undefined
          ? "-"
          : secToTextWithoutUselessUnit(
              ((Date.now() - new Date(lastEventDate).getTime()) / 1000).toString()
            )
      }
      backgroundColor={"#1F9BD9"}
      infoType={"lastEvent"}
    />
  );
};
