import { getAIResponse } from '../api/openai';
import { countEntries } from './datahelpers/countEntries';
import i18n from '../i18n'; // Import i18n
import { removeNullOrZeroValues } from './datahelpers/removeNullOrZeroValues';
import { aggregateRulesData } from './datahelpers/aggregateRulesData'; // Import the new helper function

export const fetchAIResponse = async (
  apiResponseData: any,
  setAIResponse: (response: string | undefined) => void,
  setShowAIModal: (show: boolean) => void,
  setIsRequestingAI: (isRequesting: boolean) => void
) => {
  if (!apiResponseData) return;

  // Step 1: Remove null or 0 values from apiResponseData
  const cleanedData = removeNullOrZeroValues(apiResponseData);

  // If cleanedData is empty or null, show insufficient data message
  if (!cleanedData || countEntries(cleanedData) === 0) {
    const insufficientDataMessage = `
      <div>
        <h4>${i18n.t('ai_analysis_not_done')}</h4>
        <p>${i18n.t('insufficient_data_message')}</p>
      </div>
    `;
    setAIResponse(insufficientDataMessage);
    setShowAIModal(true);
    return;
  }

  // Step 2: Get the current language setting
  const userLanguage = i18n.language || 'fr'; // Will return "en" or "fr"

  // Step 3: Add the language to each object before aggregation
  if (Array.isArray(cleanedData)) {
    cleanedData.forEach(item => {
      if (typeof item === 'object' && item !== null) {
        item.language = userLanguage;
      }
    });
  } else if (typeof cleanedData === 'object' && cleanedData !== null) {
    cleanedData.language = userLanguage;
  }

  // Step 4: Aggregate the rule data from cleanedData
  const aggregatedData = aggregateRulesData(cleanedData);
  // Convert aggregatedData to array format
  const aggregatedDataArray = Object.keys(aggregatedData).map((key) => ({
    rule: key,
    ...aggregatedData[key],
  }));
  // Check if the aggregated array has any useful information
  if (aggregatedDataArray.length === 0) {
    const insufficientDataMessage = `
      <div>
        <h4>${i18n.t('ai_analysis_not_done')}</h4>
        <p>${i18n.t('insufficient_data_message')}</p>
      </div>
    `;
    setAIResponse(insufficientDataMessage);
    setShowAIModal(true);
    return;
  }


  setIsRequestingAI(true);

  try {
    // Step 5: Send aggregated data array to backend AI service
    const response = await getAIResponse(aggregatedDataArray);
    let aiMessage = response?.aimessage || '';

    // Step 6: Clean up the response if it's in a code block format
    if (aiMessage.startsWith('```html') && aiMessage.endsWith('```')) {
      aiMessage = aiMessage.substring(7, aiMessage.length - 3).trim();
    }

    setAIResponse(aiMessage); // Store cleaned AI response for display
    setShowAIModal(true); // Show modal with AI response
  } catch (err) {
    const errorMessage = `
      <div>
        <h4>${i18n.t('error_title')}</h4>
        <p>${i18n.t('ai_error_message')}</p>
      </div>
    `;
    setAIResponse(errorMessage); // Display error message
    setShowAIModal(true);
  } finally {
    setIsRequestingAI(false);
  }

};
