import jsPDF from "jspdf";
/**
 * Adds an image to the PDF with specified dimensions and positions.
 * @param doc - jsPDF instance.
 * @param chartImage - Image data as a Base64 string.
 * @param margin - Margin to apply.
 * @param yPosition - Current Y position on the PDF.
 * @param imgWidth - Width of the image.
 * @param imgHeight - Height of the image.
 */
export const addImageToPDF = (doc: jsPDF, chartImage: string, margin: number, yPosition: number, imgWidth: number, imgHeight: number) => {
    try {
      if (imgWidth > 0 && imgHeight > 0) {
        doc.addImage(chartImage, 'PNG', margin, yPosition, imgWidth, imgHeight);
      } else {
        throw new Error('Invalid image dimensions');
      }
    } catch (error) {
      console.error('Failed to add image to PDF:', error);
      throw new Error('Error adding image to PDF.');
    }
  };
  