import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/Tabs";
import classes from "../home/Home.module.css";
import { useEffect } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const OverloadedStats = (props: IProps) => {
  useEffect(() => {
    const timer = setInterval(() => {
      // This doesn't need to update the state for now, just re-run some logic
      console.log("Refetching data every 15 seconds");
    }, 15000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(timer);
    };
  }, []); // No dependencies, runs only once when component mounts

  return (
    <>
    <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("OverloadedAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")}{" "}
          {timestampToDate(props.endDate)} {"-"} {props.title} ({i18n.t("PerHour")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.HOUR}
            graphType={GraphType.OVERLOADED_AREA_ALARM}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={false}
          />
        </div>
      </div>



      <br />
      <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("OverloadedAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")}{" "}
          {timestampToDate(props.endDate)} {"-"} {props.title} ({i18n.t("PerDay")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.DAY}
            graphType={GraphType.OVERLOADED_AREA_ALARM}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={false}
          />
        </div>
      </div>
      

      <br />
      <AlarmsList numberDays={1} heigth={"25vh"} type={4} serial={props.serial} title={props.title} />
    </>
  );
};
