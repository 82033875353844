type RuleData = {
  dates: string[];
  count: number;
  language: string;
};

type AggregatedRules = {
  [ruleKey: string]: RuleData;
};

/**
 * Aggregates rule data from an array of entries.
 * @param data - Array of objects containing rule data.
 * @returns AggregatedRules object with accumulated dates and counts per rule.
 */
export const aggregateRulesData = (data: any[]): AggregatedRules => {
  const aggregatedData: AggregatedRules = {};

  // Validate that input is an array
  if (!Array.isArray(data)) {
    console.error('Invalid input: Expected an array.');
    return aggregatedData; // Return an empty object on invalid input
  }
  
  data.forEach((entry, index) => {
    const { date, language, ...rules } = entry;

    // Verify if date exists and is a valid ISO date string
    if (!date || isNaN(Date.parse(date))) {
      console.warn(`Skipping entry at index ${index}: Invalid or missing date "${date}"`);
      return; // Skip this entry if the date is invalid
    }

    // Iterate through each rule (e.g., rule1, rule2)
    Object.keys(rules).forEach((ruleKey) => {
      const ruleValue = rules[ruleKey];

      // Ensure ruleValue is a valid number and ruleKey starts with 'rule'
      if (typeof ruleValue !== 'number' || ruleValue <= 0 || !ruleKey.startsWith('rule')) {
        console.warn(
          `Skipping invalid rule entry at index ${index}: ruleKey="${ruleKey}", ruleValue="${ruleValue}"`
        );
        return; // Skip invalid rules
      }

      // Initialize the rule entry if it doesn't exist in aggregatedData
      if (!aggregatedData[ruleKey]) {
        aggregatedData[ruleKey] = {
          dates: [],
          count: 0,
          language: typeof language === 'string' && language.trim() ? language : 'fr', // Fallback to 'fr'
        };
      }

      // Add the date to the rule's dates array
      aggregatedData[ruleKey].dates.push(date);

      // Increment the count by the rule value
      aggregatedData[ruleKey].count += ruleValue;
    });
  });

  // Log the aggregated data for debugging
  console.log('Aggregated data:', JSON.stringify(aggregatedData, null, 2));

  return aggregatedData;
};
