import jsPDF from 'jspdf';
import { renderText } from './renderText';
import { processElement } from './processElement';
import i18n from '../../../i18n';
/**
 * Process HTML tags and render the corresponding content to the PDF document.
 * Supports headers, paragraphs, and lists, applying appropriate styles and structure.
 * @param doc - jsPDF instance
 * @param aiResponse - The AI-generated HTML response to be rendered
 * @param yPosition - Initial Y position on the PDF
 * @param margin - Margin to apply
 * @returns Updated Y position after rendering
 */
export const generateAIText = (
  doc: jsPDF,
  aiResponse: string | undefined,
  yPosition: number,
  margin: number
): number => {
  const pageHeight = doc.internal.pageSize.getHeight();

  // Early return if there's no AI response
  if (!aiResponse) {
    console.warn("No AI response provided.");
    return yPosition;
  }

  // Parse the HTML content
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = aiResponse;

  // Add a title "AI-Generated Insights"
  yPosition = renderText(doc, i18n.t('AI-Generated Insights'), 16, true, yPosition, margin, pageHeight);
  
  // Process each child of the parsed HTML
  Array.from(tempDiv.children).forEach((child) => {
    yPosition = processElement(doc, child as HTMLElement, yPosition, margin, pageHeight);
  });

  return yPosition + 10; // Add some spacing after the text
};
