// src/stats/AlarmsCountCard.tsx
import { useEffect, useState } from "react"
import { getAlerts, getLastEventDate } from "../api/DetectionsRequests"

import Card from "../card/Card"
import { secToTextWithoutUselessUnit } from "../helpers/DateHelper"
import { Interval } from "../navigation/tabs/Tabs"

interface IProps {
    
}

export const AlarmsCountCard = (props: IProps) => {

    const [timer, setTimer] = useState<NodeJS.Timer>()
    const [alarmsCount, setAlarmsCount] = useState<number|undefined>(undefined)
    const [lastRequestTimestamp, setLastRequestTimestamp] = useState<number|undefined>(undefined)
    const [isLoading, setLoading] = useState<boolean>(true)

    const loadAlarmsCount = async() => {
        const now = Date.now()
        const alertsResponse = await getAlerts(now - (1000 * 60 * 60 * 24 * 4), now, Interval.DAY)
        setLoading(false)
        if(alertsResponse.error){
            alert("Une erreur s'est produite durant le chargement des alarmes")
            return
        }
        setLastRequestTimestamp(Date.now())
        setAlarmsCount(alertsResponse.data?.length)
    }
    
    useEffect(() => {
        if(timer){
            clearInterval(timer)
        }
        setTimer(setInterval(async() => {
            await loadAlarmsCount()
        }, 15000))
        return () => {
            clearInterval(timer);
          };
    }, [])
    

    return <Card
    nodePath={""} 
    style={{width: "350px", marginRight: "10px", marginBottom: "15px", display: "inline-block"}}
    title={"Nombre d'alertes depuis 4 jours"}
    value={alarmsCount}
    backgroundColor={"#1F9BD9"}
    interval={Interval.DAY}
    startDatePeriodTimestamp={Date.now() - 3600 * 1000 * 24}
    endDatePeriodTimestamp={Date.now()}/>
}

