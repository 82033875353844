import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/Tabs";
import classes from "../home/Home.module.css";
import { useEffect, useState } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const HatWearingStats = (props: IProps) => {
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const [_, setLastLoadingTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    // Set a new timer when the component is mounted
    const intervalId = setInterval(() => {
      setLastLoadingTimestamp(Date.now());
    }, 15000);
    setTimer(intervalId);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <>
    <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("HelmetNotWorn")} {timestampToDate(props.startDate)}{" "}
          {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} {"-"} {props.title}{" "}
          ({i18n.t("PerHour")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div
          className={classes.HomeTableContainer}
          style={{ height: "250px", padding: "20px" }}
        >
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.HOUR}
            graphType={GraphType.HAT_ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={false}
          />
        </div>
      </div>

    

      <br />
      <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("HelmetNotWorn")} {timestampToDate(props.startDate)}{" "}
          {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} {"-"} {props.title}{" "}
          ({i18n.t("PerDay")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div
          className={classes.HomeTableContainer}
          style={{ height: "250px", padding: "20px" }}
        >
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.DAY}
            graphType={GraphType.HAT_ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={false}
          />
        </div>
      </div>

      <br />
      <AlarmsList
        numberDays={1}
        heigth={"25vh"}
        type={2}
        serial={props.serial}
        title={props.title}
      />
    </>
  );
};
