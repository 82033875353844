import { aggregateRulesData } from './aggregateRulesData';
import { removeNullOrZeroValues } from './removeNullOrZeroValues';
import { ApiDataResponse } from '../../api/Request';
import i18n from '../../i18n';
/**
 * Processes the API response data, removing null/zero values, adding language info, and aggregating rules.
 * @param apiResponseData - API response data to be processed.
 * @returns The aggregated data ready for heatmap generation.
 */
export const processApiResponseData = async (apiResponseData: ApiDataResponse<any>) => {
    // Step 1: Remove null or 0 values from apiResponseData
    const cleanedData = removeNullOrZeroValues(apiResponseData.data);
  
    // Step 2: Get the current language setting
    const userLanguage = i18n.language || 'fr'; // Default to 'fr' if not set
  
    // Step 3: Add the language to each object before aggregation
    if (Array.isArray(cleanedData)) {
      cleanedData.forEach(item => {
        if (typeof item === 'object' && item !== null) {
          item.language = userLanguage;
        }
      });
    } else if (typeof cleanedData === 'object' && cleanedData !== null) {
      cleanedData.language = userLanguage;
    }
  
    // Step 4: Aggregate the rule data from cleanedData
    return aggregateRulesData(cleanedData);
  };