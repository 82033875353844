import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/Tabs";
import classes from "../home/Home.module.css";
import { useEffect, useState } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const AreaInStats = (props: IProps) => {
  const [, setLastLoadingTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted

    const timer = setInterval(() => {
      if (isMounted) {
        setLastLoadingTimestamp(Date.now()); // Only update if the component is mounted
      }
    }, 15000);

    return () => {
      clearInterval(timer);
      isMounted = false; // Set flag to false on component unmount
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <>

<div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("EntryZone")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")}{" "}
          {timestampToDate(props.endDate)} {"-"} {props.title} ({i18n.t("PerHour")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.HOUR}
            graphType={GraphType.AREA_IN_ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={false}
          />
        </div>
      </div>


      <br />

      <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("EntryZone")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")}{" "}
          {timestampToDate(props.endDate)} {"-"} {props.title} ({i18n.t("PerDay")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer} style={{ height: "250px", padding: "20px" }}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.DAY}
            graphType={GraphType.AREA_IN_ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={false}
          />
        </div>
      </div>
      
      <br />
      <AlarmsList numberDays={1} heigth={"25vh"} type={1} serial={props.serial} title={props.title} />
    </>
  );
};
