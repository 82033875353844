//src/ai/AIModal.tsx

import React , { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import classes from './AIComponents.module.css';
import { generatePDFReport } from './pdf/AIPDFUtils'; // Import utility
import i18n from '../i18n';
import {Interval} from "../navigation/tabs/Tabs"
import { GraphType } from '../graph/GraphTypes';
import { ApiDataResponse } from '../api/Request';
import { createHeatmap } from './pdf/createheatmap/createMatrixChart';
import { processApiResponseData } from './datahelpers/processApiResponseData';
import { calculateWeekNumber } from './pdf/createheatmap/dateshelpers/formatDate';
interface DataItem {
  rule: string;
  dates: string[];
  count: number;
  language: string;
}


interface AIModalProps {
  showAIModal: boolean;
  isRequestingAI: boolean;
  aiResponse: string | undefined;
  setShowAIModal: (show: boolean) => void;
  apiResponseData: ApiDataResponse<any> | undefined;
  dataType: 'alarms' | 'chart';
  handleAIRequest: () => void;
  graphType?: GraphType;
  interval?: Interval;
  startDatePeriodTimestamp?: number;
  endDatePeriodTimestamp?: number;
  preventUpdateAnimations?: boolean;

}

const AIModal: React.FC<AIModalProps> = ({
  showAIModal,
  isRequestingAI,
  aiResponse,
  setShowAIModal,
  apiResponseData,
  dataType,
  handleAIRequest,
  graphType,
  interval,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
  preventUpdateAnimations,
}) => {
  const [heatmapImage, setHeatmapImage] = useState<string | null>(null); // Heatmap image state

  // Function to generate heatmap when the modal is opened
  useEffect(() => {
    const generateHeatmap = async () => {
      if (showAIModal && apiResponseData && Array.isArray(apiResponseData.data)) {
        try {
          // Process the API response data
          const aggregatedData = await processApiResponseData(apiResponseData);

          // Convert aggregatedData to array format
          const aggregatedDataArray = Object.keys(aggregatedData).map((key) => ({
            rule: key,
            ...aggregatedData[key],
          }));

          // Determine the week number for the heatmap
          const firstItem = apiResponseData.data[0];
          const weekNumber = firstItem && firstItem.dates && firstItem.dates.length > 0
            ? calculateWeekNumber(firstItem.dates[0])
            : calculateWeekNumber("");

          const image = await createHeatmap(aggregatedDataArray, weekNumber, false);
          setHeatmapImage(image); // Set the heatmap image in the state
        } catch (error) {
          console.error('Error generating heatmap:', error);
        }
      }
    };

    generateHeatmap();
  }, [showAIModal, apiResponseData]);

  return (
    <>
      {showAIModal && (
        <>
          <div className={classes.ModalOverlay}></div>
          <div className={classes.AIModal}>
            <div className={classes.AIContent}>
              <h3>
                <FontAwesomeIcon icon={faRobot} /> <span>{i18n.t('Analysis')}</span>
              </h3>
              <hr />

              {/* Show loader or AI response */}
              {isRequestingAI ? (
                <div className={classes.Loader}>
                  <Loader type="Oval" color="#004aac" height={50} width={50} />
                </div>
              ) : (
                <div
                  className="AIResponse"
                  dangerouslySetInnerHTML={{ __html: aiResponse || i18n.t('No response') }}
                />
              )}

              {/* Show the generated heatmap */}
              {heatmapImage && (
                <div className={classes.HeatmapContainer}>
                  <img src={heatmapImage} alt="Heatmap" />
                </div>
              )}

              {/* Modal buttons */}
              <button onClick={() => setShowAIModal(false)}>{i18n.t('Close')}</button>

              <button
                onClick={() =>
                  generatePDFReport(
                    aiResponse,
                    dataType,
                    graphType,
                    interval,
                    startDatePeriodTimestamp,
                    endDatePeriodTimestamp,
                    preventUpdateAnimations,
                    apiResponseData
                  )
                }
              >
                {i18n.t('Download Report')}
              </button>
              <button onClick={handleAIRequest} disabled={isRequestingAI}>
                {isRequestingAI ? i18n.t('Retrying') : i18n.t('Retry AI')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AIModal;