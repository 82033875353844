import jsPDF from 'jspdf';

/**
 * Adds text to the PDF with appropriate error handling and positioning.
 * @param doc - jsPDF instance
 * @param text - The text to add
 * @param x - X position for the text
 * @param y - Y position for the text
 * @param fontSize - Font size of the text
 * @param fontStyle - Font style for the text ('normal', 'bold', etc.)
 * @param align - Text alignment ('left', 'center', 'right')
 */
const addTextToPDF = (doc: jsPDF, text: string, x: number, y: number, fontSize: number = 12, fontStyle: 'normal' | 'bold' = 'normal', align: 'left' | 'center' | 'right' = 'left') => {
  try {
    doc.setFontSize(fontSize);
    doc.setFont('Helvetica', fontStyle);
    doc.text(text, x, y, { align });
  } catch (error) {
    console.error(`Error adding text "${text}" to PDF:`, error);
    throw new Error('Failed to add text to the PDF');
  }
};

/**
 * Adds the current date to the PDF header.
 * @param doc - jsPDF instance
 * @param pageWidth - Width of the PDF page
 * @param y - Y position for the date text
 * @param margin - Margin to apply
 */
const addCurrentDateToPDF = (doc: jsPDF, pageWidth: number, y: number, margin: number) => {
  const currentDate = new Date().toLocaleDateString();
  addTextToPDF(doc, `Date: ${currentDate}`, pageWidth - margin, y, 10, 'normal', 'right');
};

/**
 * Generates a PDF header with a logo, centered title, and current date at the right.
 * If the logo fails to load, it will still proceed without it.
 * @param doc - jsPDF instance
 * @param margin - Margin to apply for the header
 * @param yPosition - Initial Y position on the PDF
 * @returns Promise<number> - Updated Y position after rendering the header
 */
export const generateHeaderPDF = (doc: jsPDF, margin: number, yPosition: number): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      const pageWidth = doc.internal.pageSize.getWidth();
      const headerHeight = 30;
      const imgWidth = 30;
      const imgHeight = 30;
      const textYPosition = yPosition + 15; // To align text vertically with the logo

      // Load the logo image
      const img = new Image();
      img.src = '/logo512.png'; // Adjust the path to your logo image

      img.onload = () => {
        try {
          if (!doc || typeof doc.addImage !== 'function') {
            throw new Error('Invalid jsPDF document instance.');
          }

          // Add the logo to the PDF (with small margin on the left)
          const logoXPosition = margin;
          doc.addImage(img, 'PNG', logoXPosition, yPosition, imgWidth, imgHeight);

          // Add site name centered with respect to the page width
          const centerXPosition = pageWidth / 2;
          addTextToPDF(doc, 'SecuriSPOT SmartView', centerXPosition, textYPosition, 16, 'bold', 'center');

          // Add current date at the right, below the text
          addCurrentDateToPDF(doc, pageWidth, textYPosition + 10, margin);

          // Resolve with updated yPosition after the header
          resolve(yPosition + headerHeight + 10);
        } catch (error) {
          console.error('Error while generating the header with image:', error);
          reject(new Error('Failed to generate PDF header.'));
        }
      };

      img.onerror = (err) => {
        console.error('Error loading image, proceeding without the logo:', err);

        // If logo fails to load, still add the text and date
        try {
          // Add the site name in the center
          const centerXPosition = pageWidth / 2;
          addTextToPDF(doc, 'SecuriSPOT SmartView', centerXPosition, textYPosition, 16, 'bold', 'center');

          // Add current date at the right
          addCurrentDateToPDF(doc, pageWidth, textYPosition + 10, margin);

          // Resolve with updated yPosition after the header
          resolve(yPosition + headerHeight + 10);
        } catch (error) {
          reject(new Error('Failed to generate PDF header without image.'));
        }
      };
    } catch (error) {
      console.error('Unexpected error in generating the PDF header:', error);
      reject(new Error('Error generating PDF header.'));
    }
  });
};
