import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import i18n from '../i18n';
import classes from './AIComponents.module.css';

interface AIButtonProps {
  isContentLoaded: boolean;
  noData: boolean;
  isRequestingAI: boolean;
  handleAIRequest: () => void;
}

const AIButton: React.FC<AIButtonProps> = ({
  isContentLoaded,
  noData,
  isRequestingAI,
  handleAIRequest,
}) => {
  return (
    <>
      {isContentLoaded && !noData && (
        <button
          className={classes.AIButton}
          onClick={handleAIRequest}
          disabled={isRequestingAI}
        >
          <FontAwesomeIcon icon={faRobot} />
          <span>{isRequestingAI ? i18n.t('Analyzing') : i18n.t('Analysis')}</span>
        </button>
      )}

      {isRequestingAI && (
        <div className={classes.ModalOverlay}>
          <div className={classes.ModalContent}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
            <p>{i18n.t('Analyzing')}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default AIButton;
