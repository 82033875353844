// src/utils/exportToCSV.ts

import { ChartDataSets } from "chart.js";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { findHost } from "../helpers/MappingSecurispot";

interface ExportToCSVParams {
  chartData: ChartDataSets[];
  interval: number;
  securispot: string;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
}

export const exportToCSV = ({
  chartData,
  interval,
  securispot,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
}: ExportToCSVParams) => {
  if (!chartData || chartData.length === 0) return;

  let csvContent = ["Type d'alarme", "Date", "Nombre d'alertes"].join(",") + "\n";

  chartData.forEach((dataset) => {
    if (dataset.data && Array.isArray(dataset.data)) {
      dataset.data.forEach((line: any) => {
        if (line && typeof line === "object" && "x" in line && "y" in line) {
          const date = line.x instanceof Date ? line.x : new Date(line.x);
          const formattedDate =
            interval === 1
              ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")
              : DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss");
          csvContent += `${dataset.label},${formattedDate},${line.y}\n`;
        }
      });
    }
  });

  const inter = interval === 1 ? "par-jour" : "par-heure";
  const securi = securispot.length
    ? findHost(securispot)
    : "tous-les-SecuriSpots";
  const startDateString = DateTime.fromMillis(startDatePeriodTimestamp).toFormat("yyyy-MM-dd");
  const endDateString = DateTime.fromMillis(endDatePeriodTimestamp).toFormat("yyyy-MM-dd");
  const fileName = `${securi}_${inter}_${startDateString}_${endDateString}.csv`;

  // https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
  const csvBlob = new Blob(["\ufeff" + csvContent], {
    type: "text/csv;charset=utf-8",
  });

  saveAs(csvBlob, fileName);
};
