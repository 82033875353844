// src/ai/pdf/AIPDFUtils.ts

import jsPDF from 'jspdf';
import { ChartDataSets } from 'chart.js';
import { Interval } from '../../navigation/tabs/Tabs';
import { GraphType } from '../../graph/GraphTypes';
import { generateHeaderPDF } from './generateHeaderPDF'; // Import the header function
import { generateAIText } from './AIText/generateAIText';
import { generateChart } from './generateChart/generateChart';
import { ApiDataResponse } from '../../api/Request';
import i18n from '../../i18n';
export const generatePDFReport = async (
  aiResponse: string | undefined,
  dataType: 'alarms' | 'chart',
  graphType?: GraphType,
  interval?: Interval,
  startDatePeriodTimestamp?: number,
  endDatePeriodTimestamp?: number,
  preventUpdateAnimations?: boolean,
  apiResponseData?: ApiDataResponse<any> | undefined
) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  const margin = 10;
  let yPosition = 20;
  // Generate header section (await since image loading is asynchronous)
  yPosition = await generateHeaderPDF(doc, margin, yPosition);

  // Generate AI text section
  yPosition = generateAIText(doc, aiResponse, yPosition, margin);

  // Handle chart data if applicable
  if (
    dataType === 'chart' &&
    apiResponseData && // Ensure apiResponseData is present
    Array.isArray(apiResponseData.data) &&
    apiResponseData.data.length > 0 &&
    graphType &&
    interval !== undefined &&
    startDatePeriodTimestamp !== undefined &&
    endDatePeriodTimestamp !== undefined
  ) {
    yPosition = await generateChart(
      doc,
      yPosition,
      margin,
      apiResponseData
    );
  } else if (dataType === 'chart') {
    console.error('No valid chart data available for the PDF generation.');
  }

  // Save the PDF after all async tasks are done
  doc.save(i18n.t('AI_Report.pdf'));
};
