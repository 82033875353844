import jsPDF from "jspdf";
import { renderText } from "./renderText";

// Variable to track if it's the first h4 encountered
let firstH4Rendered = false;

/**
 * Recursively processes child HTML elements and renders them into the PDF.
 * Handles various tags like h4, h5, p, ul, ol, and others.
 * @param doc - jsPDF instance
 * @param element - The HTML element to process
 * @param yPosition - Current Y position in the PDF
 * @param margin - Margin to apply
 * @param pageHeight - Height of the PDF page
 * @returns Updated Y position after processing the element
 */
export const processElement = (
  doc: jsPDF,
  element: HTMLElement,
  yPosition: number,
  margin: number,
  pageHeight: number
): number => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const centeredXPosition = pageWidth / 2; // Centering for titles and lines

  switch (element.tagName.toLowerCase()) {
    case 'h4': {
      // Add horizontal line and space before non-first h4 elements
      if (firstH4Rendered) {
        yPosition += 12; // More spacing before h4
        doc.setLineWidth(0.5);
        // Draw a centered line that is 60% of the page width
        const lineLength = pageWidth * 0.6;
        const lineStart = (pageWidth - lineLength) / 2;
        doc.line(lineStart, yPosition, lineStart + lineLength, yPosition); // Horizontal line
        yPosition += 10; // Space after the line
      } else {
        firstH4Rendered = true; // Mark the first h4 as rendered
      }

      // Render the centered h4 element (Title)
      doc.setFontSize(16);
      doc.setFont("Helvetica", "bold");
      doc.text(element.textContent || '', centeredXPosition, yPosition, { align: "center" });
      yPosition += 14; // Additional space after the title
      break;
    }

    case 'h5': {
      // Add space before h5 elements (Subtitle)
      yPosition += 8;
      // Indent and render the h5 element slightly to the right
      const subtitleIndent = margin + 10;
      doc.setFontSize(14);
      doc.setFont("Helvetica", "normal");
      doc.text(element.textContent || '', subtitleIndent, yPosition);
      yPosition += 10; // Space after subtitle
      break;
    }

    case 'p': {
      // Ensure there's some space before paragraphs
      yPosition += 6;
      // Render paragraph content
      yPosition = renderText(doc, element.textContent || '', 12, false, yPosition, margin, pageHeight);
      break;
    }

    case 'ul': {
      // For unordered lists, process each list item with proper margin
      const listItems = element.querySelectorAll('li');
      listItems.forEach((li: HTMLElement) => {
        yPosition = renderText(doc, `• ${li.textContent}`, 12, false, yPosition, margin + 10, pageHeight);
      });
      yPosition += 6; // Add extra space after the list
      break;
    }

    case 'ol': {
      // For ordered lists, process each list item with numbering
      const orderedListItems = element.querySelectorAll('li');
      orderedListItems.forEach((li: HTMLElement, index: number) => {
        yPosition = renderText(doc, `${index + 1}. ${li.textContent}`, 12, false, yPosition, margin + 10, pageHeight);
      });
      yPosition += 6; // Add extra space after the list
      break;
    }

    default: {
      // Render unrecognized tags as paragraphs with spacing
      yPosition += 6;
      yPosition = renderText(doc, element.textContent || '', 12, false, yPosition, margin, pageHeight);
      break;
    }
  }

  return yPosition;
};
