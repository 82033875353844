// ./src/graph/graphprops/AlertsEvolutionWithLegendProps.ts
import { ChartConfiguration } from "chart.js";
import { Interval } from "../../navigation/tabs/Tabs";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";

export const graphProps = (
  dates: Date[],
  interval: Interval,
  startDateTimestamp: number,
  endDateTimestamp: number
): ChartConfiguration => {
  let minDate = undefined;
  let maxDate = undefined;
  if (
    dates !== undefined &&
    dates.length > 0 &&
    dates[0].getTime() !== undefined
  ) {
    minDate = dates[0].getTime();
    maxDate = dates[dates.length - 1].getTime();
  }

  const props: ChartConfiguration = {
    type: "bar",
    data: {},
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "bottom",
      },
      scales: {
        xAxes: [
          xAxeTimeDefaultProps(
            minDate,
            maxDate,
            interval,
            startDateTimestamp,
            endDateTimestamp,
            true,
            false
          ),
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
      },
    },
  };
  return props;
};
