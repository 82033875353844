import { useEffect, useState } from "react";
import {
  GetAlarmsApiResponse,
  getAlerts,
} from "../api/DetectionsRequests";
import classes from "../home/Home.module.css";
import Loader from "react-loader-spinner";
import { Interval } from "../navigation/tabs/Tabs";
import { getI18n } from "react-i18next";
import { DateTime } from "luxon";
import saveAs from "file-saver";
import { startOfDay, subDays } from "date-fns";
import { DatePicker } from "rsuite";
import i18n from "../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { findHost } from "../helpers/MappingSecurispot";
interface IProps {
  numberDays: number | undefined;
  heigth: string | undefined;
  type: number | undefined;
  serial: string | undefined;
  title: string | undefined;
}

export const AlarmsList = (props: IProps) => {
  const currentUrl: string = window.location.href;

  const todayMidnightTimestamp = new Date(new Date().setHours(0, 0, 0, 0)).getTime();

  const [alarms, setAlarms] = useState<GetAlarmsApiResponse | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [start, setStart] = useState<number>(
    todayMidnightTimestamp - 1000 * 60 * 60 * 24 * (props.numberDays! - 1)
  );
  const [end, setEnd] = useState<number>(Date.now());

  function extractDescriptionAndPeople(description: any): { statusDescription: string; nbPersons: number } {
    let descString: string;

    if (typeof description === 'string') {
      descString = description;
    } else if (Array.isArray(description) && description.length > 0 && typeof description[0] === 'string') {
      descString = description[0];
    } else {
      console.warn('Description is not a string or array of strings:', description);
      return { statusDescription: '', nbPersons: 0 };
    }

    // Regex to match a number at the beginning of the string
    const match = descString.match(/^(\d+)\s+(.*)/);

    if (match) {
      const nbPersons = parseInt(match[1], 10);
      const statusDescription = match[2].trim();
      return { statusDescription, nbPersons };
    } else {
      // If no match, return the original description and 0 for the number of people
      return { statusDescription: descString, nbPersons: 0 };
    }
  }

  const loadDetections = async () => {
    setLoading(true);
    const response = await getAlerts(
      start,
      end,
      Interval.DAY,
      props.serial,
      props.type
    );
    setLoading(false);

    if (response.error) {
      console.error("error during detections loading : ", response.error);
      return;
    }

    setAlarms(response.data);
  };

  const onDateChange = (date: Date | undefined) => {
    if (date) {
      setStart(date.getTime());
      setEnd(date.getTime() + (1000 * 60 * 60 * 24 - 1));
    }
  };

  useEffect(() => {
    loadDetections();
  }, [start, props.serial]);

  const downloadData = () => {
    if (alarms === undefined) {
      return;
    }

    const csvHeader = "Securispot;Date;Description;Nombre de personnes;Durée (sec)\n";  

    const csvContent =
      csvHeader +
      alarms
        .map((alarm) => {
          const { statusDescription, nbPersons } = extractDescriptionAndPeople(alarm.description);
          return `${alarm.securispot};${DateTime.fromISO(alarm.date).toFormat("yyyy-MM-dd HH:mm:ss")};${statusDescription};${nbPersons};${alarm.duration.toFixed(2)}`;
        })
        .join("\n");

    const securi = props.serial ? findHost(props.serial) : "tous-les-SecuriSpots";
    const startDateString = DateTime.fromISO(new Date(start).toISOString()).toFormat("yyyy-MM-dd");
    const endDateString = DateTime.fromISO(new Date(end).toISOString()).toFormat("yyyy-MM-dd");
    const fileName =
      `${securi}_${startDateString}` +
      `${startDateString !== endDateString ? "_" + endDateString : ""}` +
      "_liste.csv";

    const csvBlob = new Blob(["\ufeff" + csvContent], {
      type: "text/csv;charset=utf-8",
    });

    saveAs(csvBlob, fileName);
  };

  if (isLoading) {
    return (
      <div className={classes.StatsItemContainer}>
        <label>{i18n.t("EventList")}</label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer}>
          <div style={{ padding: "20px" }}>
            <Loader type={"Oval"} width={40} height={40} color={"#0095ff"} />
          </div>
        </div>
      </div>
    );
  }
  let customLocale = {
    sunday: i18n.t('Su'),
    monday: i18n.t('Mo'),
    tuesday: i18n.t('Tue'),
    wednesday: i18n.t('We'),
    thursday: i18n.t('Thu'),
    friday: i18n.t('Fri'),
    saturday: i18n.t('Sat'),
    ok: 'OK',
    today: i18n.t('Today'),
    yesterday: i18n.t('Yesterday'),
    hours: i18n.t('Hours'),
    minutes: i18n.t("Minutes"),
    seconds: i18n.t("Seconds"),
  };
  
  if (alarms && alarms.length === 0) {
    return (
      <div className={classes.StatsItemContainer}>
        <div className={classes.HeadRow}>
          {start === end ? (
            <label>
              {i18n.t("EventList")} ({DateTime.fromMillis(start).toFormat("yyyy-MM-dd")}) - {props.title} -{" "}
              {alarms?.length} {i18n.t("Alerts")}
            </label>
          ) : (
            <label>
              {i18n.t("EventList")} ({DateTime.fromMillis(start).toFormat("yyyy-MM-dd")} -{" "}
              {DateTime.fromMillis(end).toFormat("yyyy-MM-dd")}) - {props.title} - {alarms?.length}{" "}
              {i18n.t("Alerts")}
            </label>
          )}
          {!currentUrl.endsWith("alarms") && (
            <DatePicker locale={customLocale}
              disabledDate={(date: Date | undefined) => {
                const firstAllowed: Date = new Date(2023, 0, 1);
                return date!.getTime() > Date.now() || date!.getTime() < firstAllowed.getTime();
              }}
              placement={"bottomEnd"}
              cleanable={false}
              value={start !== undefined && end !== undefined ? new Date(start) : undefined}
              placeholder={getI18n().t("SelectPeriod")}
              onChange={onDateChange}
              ranges={[
                { label: i18n.t("Today"), value: startOfDay(new Date()) },
                { label: i18n.t("Yesterday"), value: startOfDay(subDays(new Date(), 1)) },
              ]}
            />
          )}
        </div>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.HomeTableContainer}>
          <div>
            <label className={classes.DetectionsListNoItemLabel}>
              {i18n.t("NoAlarm")}
            </label>
          </div>
        </div>
      </div>
    );
  }

  const filteredAndSortedAlarms = alarms
    ?.filter((alarm) => alarm.duration > 0)
    .sort((a, b) => (new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1));

  return (
    <div className={classes.StatsItemContainer}>
      <div className={classes.HeadRow}>
        <label>
          {start === end
            ? `${i18n.t("EventList")} (${DateTime.fromMillis(start).toFormat("yyyy-MM-dd")}) - ${props.title} - ${alarms?.length} ${i18n.t("Alerts")}`
            : `${i18n.t("EventList")} (${DateTime.fromMillis(start).toFormat("yyyy-MM-dd")} - ${DateTime.fromMillis(end).toFormat("yyyy-MM-dd")}) - ${props.title} - ${alarms?.length} ${i18n.t("Alerts")}`}
        </label>
        {!currentUrl.endsWith("alarms") && (
          <DatePicker locale={customLocale}
            disabledDate={(date: Date | undefined) => {
              const firstAllowed: Date = new Date(2023, 0, 1);
              return date!.getTime() > Date.now() || date!.getTime() < firstAllowed.getTime();
            }}
            placement="bottomEnd"
            cleanable={false}
            value={start !== undefined && end !== undefined ? new Date(start) : undefined}
            placeholder={i18n.t("SelectPeriod")}
            onChange={onDateChange}
            ranges={[
              { label: i18n.t("Today"), value: startOfDay(new Date()) },
              { label: i18n.t("Yesterday"), value: startOfDay(subDays(new Date(), 1)) },
            ]}
          />
        )}
      </div>
      <div className={classes.HomeTitleSeparator} />
      <div
        className={classes.HomeTableContainer}
        style={{ overflowY: "auto", maxHeight: props.heigth ? props.heigth : "61vh" }}
      >
        {filteredAndSortedAlarms?.length === 0 ? (
          <label style={{ padding: "20px", display: "block" }}>
            {i18n.t("NoAlarm")}
          </label>
        ) : (
          <table className={classes.Table}>
      <thead>
        <tr>
          <th>{i18n.t("Securispot")}</th>
          <th>{i18n.t("Date")}</th>
          <th>{i18n.t("Nombre de personnes")}</th> {/* Moved this before "Description" */}
          <th>{i18n.t("Description")}</th>
          <th>{i18n.t("Duration")} (sec)</th>
        </tr>
      </thead>
      <tbody>
        {filteredAndSortedAlarms?.map((alarm) => {
          const { statusDescription, nbPersons } = extractDescriptionAndPeople(alarm.description);
          return (
            <tr
              key={alarm.id}
              className={
                Date.now() - new Date(alarm.date).getTime() < 1000 * 10
                  ? classes.HomeRecentEventRow
                  : undefined
              }
            >
              <td>{alarm.securispot}</td>
              <td>{DateTime.fromISO(alarm.date).toFormat("yyyy-MM-dd HH:mm:ss")}</td>
              <td>{nbPersons}</td> {/* Moved this before "Description" */}
              <td>{statusDescription}</td>
              <td>{alarm.duration.toFixed(2)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>

        )}
      </div>
      <div className={classes.ContainerDownloadButton}>
        <button className={classes.DownloadButton} onClick={downloadData}>
          <FontAwesomeIcon icon={faDownload} />
          <span>{i18n.t("Export")}</span>
        </button>
      </div>
    </div>
  );
};
