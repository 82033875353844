import jsPDF from 'jspdf';
import { createHeatmap } from '../createheatmap/createMatrixChart'; // Import the new helper function
import { ApiDataResponse } from '../../../api/Request';
import { processApiResponseData } from '../../datahelpers/processApiResponseData';
import { addImageToPDF } from './addImageToPDF';
import { calculateWeekNumber } from '../createheatmap/dateshelpers/formatDate';
import i18n from '../../../i18n';
/**
 * Adds a horizontal line to the PDF.
 * @param doc - jsPDF instance.
 * @param yPosition - Y position for the line.
 * @param margin - Margin for the line.
 * @param pageWidth - Width of the PDF page.
 * @returns Updated Y position after rendering the line.
 */
const addHorizontalLine = (doc: jsPDF, yPosition: number, margin: number, pageWidth: number): number => {
  doc.setLineWidth(0.5);
  doc.line(margin, yPosition, pageWidth - margin, yPosition); // Draw horizontal line across the page
  return yPosition + 7; // Return updated yPosition with some space after the line
};

/**
 * Adds a title (h4-like) to the PDF before the heatmap section.
 * @param doc - jsPDF instance.
 * @param yPosition - Y position to start rendering the title.
 * @param margin - Margin to apply for the title.
 * @param title - Title text.
 * @returns Updated Y position after rendering the title.
 */
const addSectionTitle = (doc: jsPDF, yPosition: number, margin: number, title: string): number => {
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.text(title, margin, yPosition);
  return yPosition + 10;
};
/**
 * Generates a chart and adds it to the PDF.
 * @param doc - jsPDF instance.
 * @param yPosition - Y position to start rendering the chart.
 * @param margin - Margin to apply for the chart.
 * @param apiResponseData - API response data to generate the chart.
 * @returns Updated Y position after rendering the chart.
 */
export const generateChart = async (
  doc: jsPDF,
  yPosition: number,
  margin: number,
  apiResponseData?: ApiDataResponse<any>
): Promise<number> => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Define the fixed size for the heatmap image
  const imgWidth = 180; // Fixed width
  const imgHeight = 120; // Fixed height

  // Early return if apiResponseData is not provided or invalid
  if (!apiResponseData || !apiResponseData.data) {
    console.error('apiResponseData is undefined or does not contain data');
    return yPosition;
  }

  try {
    // Process the API response data
    const aggregatedData = await processApiResponseData(apiResponseData);

    // Convert aggregatedData to array format
    const aggregatedDataArray = Object.keys(aggregatedData).map((key) => ({
      rule: key,
      ...aggregatedData[key],
    }));

    // Determine the week number for the heatmap
    const firstItem = apiResponseData.data[0];
    const weekNumber = firstItem && firstItem.dates && firstItem.dates.length > 0
      ? calculateWeekNumber(firstItem.dates[0])
      : calculateWeekNumber("");


    // Generate the heatmap and get its image data
    const chartImage = await createHeatmap(aggregatedDataArray, weekNumber, false);

    if (!chartImage) {
      throw new Error('Failed to generate chart image.');
    }

    // Calculate available vertical space
    const availableSpace = pageHeight - yPosition - margin;

    // If the image height exceeds the available space, add a new page
    if (imgHeight + 10 > availableSpace) {
      doc.addPage();
      yPosition = margin; // Reset Y position for new page
    }

    // Add horizontal line and section title before the chart
    yPosition = addHorizontalLine(doc, yPosition, margin, pageWidth);
    yPosition = addSectionTitle(doc, yPosition, margin, i18n.t('Heatmap Section')); // Add heatmap section title

    // Add the heatmap image to the PDF
    addImageToPDF(doc, chartImage, margin, yPosition, imgWidth, imgHeight);
    
    return yPosition + imgHeight + 10;
  } catch (error) {
    console.error('Error generating chart:', error);
    return yPosition;
  }
};
