
// src/utils/ChartUtils.ts

import { Chart, ChartDataSets } from 'chart.js';
import 'chartjs-plugin-zoom'; // Import the zoom plugin
import { GraphType } from '../graph/GraphTypes';
import { graphProps as incidentsEvolutionProps } from "../graph/graphprops/IncidentsEvolutionProps";
import { graphProps as alertsEvolutionProps } from "../graph/graphprops/AlertsEvolutionProps";
import { graphProps as alertsEvolutionWithLegendProps } from "../graph/graphprops/AlertsEvolutionWithLegendProps";
import { graphProps as statusEvolutionProps } from "../graph/graphprops/StatusEvolutionProps";
import { Interval } from "../navigation/tabs/Tabs";

interface CreateChartParams {
  ctx: CanvasRenderingContext2D;
  graphType: GraphType;
  datasets: ChartDataSets[];
  dates: Date[];
  labels?: string[] | Array<string[]>;
  maxValue?: number;
  options?: Chart.ChartOptions;
  interval: Interval;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
  preventUpdateAnimations: boolean;
}

export const createChart = ({
  ctx,
  graphType,
  datasets,
  dates,
  labels,
  maxValue,
  options = {},
  interval,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
  preventUpdateAnimations,
}: CreateChartParams): Chart => {
  let chartConfig: Chart.ChartConfiguration;

  // Select the appropriate chart configuration based on the graph type
  switch (graphType) {
    case GraphType.INCIDENTS_COUNT:
      chartConfig = incidentsEvolutionProps(
        dates,
        interval,
        startDatePeriodTimestamp,
        endDatePeriodTimestamp
      );
      break;
    case GraphType.OVERLOADED_AREA_ALARM:
    case GraphType.AREA_IN_ALARMS_EVOLUTION:
    case GraphType.HAT_ALARMS_EVOLUTION:
    case GraphType.LYING_PERSON_ALARMS_EVOLUTION:
    case GraphType.COLLISION_ALARM_EVOLUTION:
      chartConfig = alertsEvolutionProps(
        dates,
        interval,
        startDatePeriodTimestamp,
        endDatePeriodTimestamp
      );
      break;
    case GraphType.ALARMS_EVOLUTION:
      chartConfig = alertsEvolutionWithLegendProps(
        dates,
        interval,
        startDatePeriodTimestamp,
        endDatePeriodTimestamp
      );
      break;
    case GraphType.STATUS_EVOLUTION:
      chartConfig = statusEvolutionProps(
        dates,
        interval,
        startDatePeriodTimestamp,
        endDatePeriodTimestamp
      );
      break;
    default:
      throw new Error('Invalid graph type');
  }

  // Merge datasets and labels into the chart configuration
  chartConfig.data = {
    datasets: datasets.map((dataset, index) => ({
      ...dataset,
      backgroundColor: [
        '#1f77b4', // Blue
        '#ff7f0e', // Orange
        '#9467bd', // Purple
        '#17becf', // Teal
        '#7f7f7f'  // Gray
      ][index % 5],  // Cycle through the color array for each dataset
      borderColor: [
        '#1f77b4', // Blue
        '#ff7f0e', // Orange
        '#9467bd', // Purple
        '#17becf', // Teal
        '#7f7f7f'  // Gray
      ][index % 5],  // Match the border color
      borderWidth: 2,
    })),
    labels,
  };
  
  // Add zoom and pan options
  chartConfig.options = {
    ...chartConfig.options,
    plugins: {
      zoom: {
        zoom: {
          enabled: true,
          mode: 'x',  // Allow zooming on both axes
          speed: 0.05, // Zoom speed
        },
        pan: {
          enabled: true,
          mode: 'xy',  // Allow panning on both axes
          speed: 10,   // Pan speed
        },
      },
    },
    ...options, // Merge any additional options
  };

  // Handle animation settings
  if (preventUpdateAnimations && chartConfig.options.animation) {
    chartConfig.options.animation.duration = 0;
  }

  // Create and return the chart instance
  const chart = new Chart(ctx, chartConfig);
  return chart;
};
