import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/Tabs";
import classes from "../home/Home.module.css";
import { useEffect, useState } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const GlobalDashboardStats = (props: IProps) => {
  const [, setLastLoadingTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    // Set up the interval to update the timestamp every 15 seconds
    const timer = setInterval(() => {
      setLastLoadingTimestamp(Date.now());
    }, 15000);

    // Clean up the interval when the component unmounts or startDate changes
    return () => {
      clearInterval(timer);
    };
  }, [props.startDate]); // Effect runs when startDate changes

  return (
    <>
    <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("AlarmFrom")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerHour")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.GraphContainer}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.HOUR}
            graphType={GraphType.ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={true}
          />
        </div>
      </div>
      
      <div className={classes.StatsItemContainer}>
        <label>
          {i18n.t("AlarmFrom")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerDay")})
        </label>
        <div className={classes.HomeTitleSeparator} />
        <div className={classes.GraphContainer}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.DAY}
            graphType={GraphType.ALARMS_EVOLUTION}
            preventUpdateAnimations={false}
            nodePath={""}
            securispot={props.serial}
            export={true}
          />
        </div>
      </div>

      

      <AlarmsList numberDays={1} heigth={"25vh"} type={undefined} serial={props.serial} title={props.title} />
    </>
  );
};
